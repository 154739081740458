import React, { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';

import { ICD9Input } from '#atoms';
import { useFirstRender } from '#hooks';

import { LaboratoryResultsForm } from './LaboratoryResultsForm';

import './LaboratoryForm.css';

export function LaboratoryForm({ form }) {
  const { control } = form;
  // const { t } = useTranslation();
  const isFirstRender = useFirstRender();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'json_content',
  });

  useEffect(() => {
    if (isFirstRender && fields.length < 1) {
      append({ icd: '', values: [] });
    }
  }, [isFirstRender, append, fields]);

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Card key={item.id} className='mb-4'>
            <Card.Header className='text-center'>
              <h4>Podaj wyniki badania</h4>
              <Button
                type='button'
                variant='close'
                className='LaboratoryForm__delete'
                aria-label='Close'
                onClick={() => {
                  remove(index);
                }}
              />
            </Card.Header>
            <Card.Body>
              <div className='mb-3'>
                <ICD9Input
                  label={`json_content.${index}.icd`}
                  form={form}
                  initialValue={item.icd}
                />
              </div>
              <LaboratoryResultsForm nestIndex={index} form={form} />
            </Card.Body>
          </Card>
        );
      })}
      <div className='d-grid gap-2 mb-4'>
        <Button
          variant='outline-primary'
          size='lg'
          className='animate-up-2 d-grid gap-2'
          onClick={() => {
            append({ icd: '', values: [] });
          }}
        >
          Dodaj wyniki badania
        </Button>
      </div>
    </>
  );
}
