import { useEffect, useState, useCallback } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { alert } from '#utils';

export const DocumentWatcher = ({
  useFetchDocuments,
  useLazyFetchDocumentQuery,
  getStatusField,
  pagePath,
  title,
  text,
}) => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);

  const { data } = useFetchDocuments(undefined, {
    pollingInterval: documents.length > 0 ? 10000 : 0,
    refetchOnMountOrArgChange: true,
  });

  const [getDocument] = useLazyFetchDocumentQuery();

  const alertDocument = useCallback(
    async (id) => {
      try {
        let document = await getDocument({ id }).unwrap();

        // Sometimes the request may not return data as it might be fetching cached data.
        // In such cases, we need to make a second request to ensure we obtain the most up-to-date information.
        if (!document) document = await getDocument({ id }).unwrap();

        if (!document) return;

        const status = getStatusField(document);
        const isFinished = status !== 'IN_PROGRESS' && status !== 'NOT_STARTED';
        const isSuccessful = status === 'FINISHED';

        if (isFinished) {
          alert
            .fire({
              icon: isSuccessful ? 'success' : 'error',
              title: isSuccessful ? title[0] : title[1],
              text: isSuccessful ? text[0] : text[1],
              showCancelButton: true,
              confirmButtonText: 'Show Document Page',
              cancelButtonText: 'Close alert',
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                navigate(generatePath(pagePath, { id }));
              }
            });
        }
      } catch (e) {
        console.log('Error while fetching medical document', e);
      }
    },
    [getDocument, navigate],
  );

  useEffect(() => {
    if (!data) return;

    const nextDocuments = data.results;

    const outdatedDocuments = documents.filter(
      (prev) => !nextDocuments.find((next) => prev.id === next.id),
    );

    outdatedDocuments.map(({ id }) => id).forEach(alertDocument);

    setDocuments(nextDocuments);
  }, [data]);

  return;
};
