import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFetchHealthReportsQuery } from '#store';
import { HealthReportsGallery } from '#components';
import { Routes } from '#Routes';

const HealthReportsListPage = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useFetchHealthReportsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <div>
      <div className='d-block mb-4'>
        <h4>Health reports</h4>
        <p className='mb-0'>Your health reports dashboard.</p>
      </div>
      <div className='mb-4'>
        {isLoading ? (
          <div className='d-flex justify-content-center align-items-center m-5'>
            <Spinner />
            <span className='ms-2'>Loading...</span>
          </div>
        ) : (
          <HealthReportsGallery data={data?.results} />
        )}
      </div>
      <Row className='justify-content-between align-items-center mb-4'>
        <Col xs={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className='d-grid'>
          <Button
            variant='primary'
            size='lg'
            onClick={() => {
              navigate(Routes.HealthReportCreate.path);
            }}
            className='btn-block d-flex align-items-center justify-content-center'
          >
            <PlusIcon className='icon icon-xs me-2' /> Create new Health Report
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(HealthReportsListPage);
