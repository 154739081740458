import React from 'react';
import { Col, Badge, Table } from 'react-bootstrap';
import moment from 'moment';
import { Document } from '#atoms';

export const MedicalDocument = ({ data, hideAI }) => {
  return (
    <Document>
      <Document.Title
        badge={{
          text: data?.process_type,
          bg: data?.process_type === 'MANUAL' ? 'warning' : 'success',
        }}
      >
        {data?.document_type === 'LAB' ? 'Badanie laboratoryjne' : 'Diagnoza lekarska'}
      </Document.Title>

      <Document.Information>
        <>
          <Col xs={12} className='mb-2'>
            Document type: <span className='fw-bold'>{data?.document_type}</span>
          </Col>

          <Col xs={12} className='mb-2'>
            Processing: <span className='fw-bold'>{data?.process_type}</span>
          </Col>

          <Col xs={12} className='mb-2'>
            Processing finished:{' '}
            <span className='fw-bold'>{data?.process_status === 'FINISHED' ? 'YES' : 'NO'}</span>
          </Col>

          <Col xs={12} className='mb-2'>
            {data?.tags?.map((tag) => (
              <Badge key={tag} bg='primary' className='m-1' style={{ fontSize: '1rem' }}>
                {tag}
              </Badge>
            ))}
          </Col>
        </>
        <>
          <Col xs={6} className='mb-2'>
            Date created:
          </Col>
          <Col xs={6} className='mb-2'>
            <span className='fw-bold'>{moment(data?.created).format('y-MM-DD HH:mm')}</span>
          </Col>

          <Col xs={6} className='mb-2'>
            Date modified:
          </Col>
          <Col xs={6} className='mb-2'>
            <span className='fw-bold'>{moment(data?.modified).format('y-MM-DD HH:mm')}</span>
          </Col>

          <Col xs={6} className='mb-2'>
            Date issued:
          </Col>
          <Col xs={6} className='mb-2'>
            <span className='fw-bold'>{moment(data?.date).format('y-MM-DD')}</span>
          </Col>
        </>
      </Document.Information>

      <Document.Loader
        isLoading={data?.process_status === 'IN_PROGRESS'}
        text='Document is processing...'
      />

      {data?.json_content?.map((properties, i) => (
        <div key={i} className='mb-5'>
          <h5>{properties.category_name}</h5>
          <Table responsive className='mb-0'>
            <thead className='bg-light border-top'>
              <tr>
                <th className='border-0 text-left'>Name</th>
                <th className='border-0'>Value</th>
                <th className='border-0'>Unit</th>
                <th className='border-0'>Reference</th>
              </tr>
            </thead>
            <tbody className='border-0'>
              {properties.values?.map(({ name, value, unit, reference }, i) => (
                <tr key={i} className='border-bottom'>
                  <th className='h6 text-left fw-bold'>{name}</th>
                  <td>{value}</td>
                  <td>{unit}</td>
                  <td>
                    {reference[0]} - {reference[1]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}

      <Document.Text title='Content' content={data?.text_content} />

      {!hideAI && (
        <Document.Text
          title='AI Report'
          content={data?.report_content || 'This document does not have generated AI Report yet.'}
        />
      )}

      <Document.Loader
        isLoading={data?.report_status === 'IN_PROGRESS'}
        text='AI report is generating...'
      />

      <div className='d-flex justify-content-end mt-4'>
        {data?.approved ? (
          <div>
            <h5>Document approved by:</h5>
            <span className='ms-2'>{data?.approved_by}</span>
          </div>
        ) : (
          <h5>Document not approved by doctor.</h5>
        )}
      </div>
    </Document>
  );
};
