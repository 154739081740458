import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Headers() {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t('headers.title')}</title>
      <meta name='description' content={t('headers.description')} />
    </Helmet>
  );
}

export default Headers;
