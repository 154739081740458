import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FolderDownloadIcon, TrashIcon, CollectionIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { Routes } from '#Routes';
import { alert } from '#utils';
import { useDeleteHealthReportMutation } from '#store';

export const HealthReportActions = ({ toPDF, data }) => {
  const navigate = useNavigate();

  const [deleteHealthReportMutation] = useDeleteHealthReportMutation();

  const processingInProgressAlert = () => {
    alert.fire({
      icon: 'warning',
      title: 'Health Report processing in progress',
      text: 'Your Health Report is being processed. Please wait until processing is finished.',
      confirmButtonText: 'Ok',
    });
  };

  const viewHealthReportsList = () => {
    navigate(Routes.HealthReportsList.path);
  };

  const deleteHealthReport = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        icon: 'warning',
        title: 'Confirm deleting',
        text: 'Are you sure do you want to delete this Health Report?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          alert.fire({
            title: 'Deleting Health Report...',
            didOpen: () => alert.showLoading(),
          });

          const { error } = await deleteHealthReportMutation({ id: data.id });

          if (error) {
            return alert.fire({
              icon: 'error',
              title: 'Error!',
              text:
                error.data?.message ||
                'There was an error when deleting Health Report. Please try again.',
            });
          }

          return alert
            .fire({
              icon: 'success',
              title: 'Health Report deleted!',
              text: 'Your Health Report has been deleted.',
            })
            .then(viewHealthReportsList);
        }
      });
  };

  const downloadHealthReport = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        title: 'Generating Health Report...',
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        didOpen: async () => {
          alert.showLoading();
          await toPDF();
          alert.close();
        },
      })
      .then(() => {
        return alert.fire({
          icon: 'success',
          title: 'PDF generated!',
          text: 'Your Health Report PDF has been generated. Check your downloads folder.',
        });
      });
  };

  if (!data) return;

  return (
    <div>
      <OverlayTrigger overlay={<Tooltip className='m-0'>Health Reports List</Tooltip>}>
        <Button variant='gray-800' className='ms-2' onClick={viewHealthReportsList}>
          <CollectionIcon className={'icon icon-xs text-white'} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip className='m-0'>Download</Tooltip>}>
        <Button variant='gray-800' className='ms-2' onClick={downloadHealthReport}>
          <FolderDownloadIcon className={'icon icon-xs text-white'} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip className='m-0'>Delete</Tooltip>}>
        <Button variant='danger' className='ms-2' onClick={deleteHealthReport}>
          <TrashIcon className={'icon icon-xs text-white'} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
