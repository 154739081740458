import React from 'react';
import {
  useFetchMedicalDocumentProcessingQuery,
  useFetchMedicalDocumentReportingQuery,
  useLazyFetchMedicalDocumentQuery,
  useFetchHealthReportsProcessingQuery,
  useLazyFetchHealthReportQuery,
} from '#store';
import { DocumentWatcher } from './DocumentWatcher';
import { Routes } from '#Routes';

export const WatcherManager = () => {
  return (
    <>
      <DocumentWatcher
        useFetchDocuments={useFetchMedicalDocumentProcessingQuery}
        useLazyFetchDocumentQuery={useLazyFetchMedicalDocumentQuery}
        getStatusField={(d) => d.process_status}
        pagePath={Routes.MedicalDocument.path}
        title={['Documented processing finished!', 'Documented processing failed!']}
        text={[
          'Your document has been processed successfully. Please check results and confirm data compliance.',
          'Processing of your document has failed. Please validate all of the provided data and try processing again. In case of further issues please contact our support.',
        ]}
      />
      <DocumentWatcher
        useFetchDocuments={useFetchMedicalDocumentReportingQuery}
        useLazyFetchDocumentQuery={useLazyFetchMedicalDocumentQuery}
        getStatusField={(d) => d.report_status}
        pagePath={Routes.MedicalDocument.path}
        title={['AI Reported finished!', 'AI Reported generation failed!']}
        text={[
          'Your document has been reported successfully. Please check results and confirm data compliance.',
          'Reporting of your document has failed. Please validate all of the provided data and try reporting again. In case of further issues please contact our support.',
        ]}
      />
      <DocumentWatcher
        useFetchDocuments={useFetchHealthReportsProcessingQuery}
        useLazyFetchDocumentQuery={useLazyFetchHealthReportQuery}
        getStatusField={(d) => d.process_status}
        pagePath={Routes.HealthReport.path}
        title={['Health report processing finished!', 'Health report processing failed!']}
        text={[
          'Your health report has been processed successfully.',
          'Processing of your health report has failed. In case of further issues please contact our support.',
        ]}
      />
    </>
  );
};
