import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { parse } from 'csv-parse/browser/esm/sync';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList as List } from 'react-window';
import { Controller } from 'react-hook-form';

import file from './icd9.csv';

function ICD9List() {
  const height = 35;
  const { options, children, maxHeight, getValue } = this.props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      name='icd9type'
    >
      {({ index, style }) => (
        <div name='icd9type' style={style}>
          {children[index]}
        </div>
      )}
    </List>
  );
}

export function ICD9Input({ form, label, initialValue }) {
  const [laboratoryExaminations, setLaboratoryExaminations] = useState([]);
  const [defaultValue, setDefaultValue] = useState();

  const { control } = form;

  console.log('initialValue: ', !!initialValue);

  useEffect(() => {
    fetch(file)
      .then((response) => response.text())
      .then((data) => {
        const test = parse(data, {
          skip_empty_lines: false,
        });

        const laboratoryTests = test.slice(2).map((laboratory) => {
          return { value: laboratory[4], label: `${laboratory[4]} - ${laboratory[5]}` };
        });

        const jsonObject = laboratoryTests.map(JSON.stringify);
        const uniqueSet = new Set(jsonObject);
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

        setLaboratoryExaminations(uniqueArray);

        if (initialValue) {
          const foundIcd = uniqueArray.find((icd) => icd.value === initialValue);
          setDefaultValue(foundIcd);
        }
      });
  }, [initialValue]);

  return laboratoryExaminations.length > 0 ? (
    <Form.Group className='select-box'>
      <Controller
        control={control}
        name={label}
        render={({ field: { onChange, ref } }) => (
          <CreatableSelect
            inputRef={ref}
            components={{ ICD9List }}
            options={laboratoryExaminations}
            defaultValue={defaultValue}
            type='text'
            name={label}
            onChange={(val) => onChange({ value: val.value, label: val.label })}
          />
        )}
      />
    </Form.Group>
  ) : (
    ''
  );
}
