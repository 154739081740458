import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { WithContext as ReactTags } from 'react-tag-input';
import { Controller } from 'react-hook-form';

export const TagsInput = ({
  form,
  title,
  placeholder,
  initialValues,
  inputPosition = 'bottom',
  onChange: onChangeCallback = () => {},
}) => {
  const { control } = form;

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(
      initialValues?.map((tag) => {
        return { id: tag, text: tag };
      }),
    );
  }, [initialValues]);

  return (
    <Form.Group id='tags'>
      {title && <Form.Label>{title}</Form.Label>}
      <Controller
        control={control}
        name='tags'
        render={({ field: { onChange } }) => (
          <ReactTags
            classNames={{
              tagInputField: 'form-control',
              tag: `badge bg-primary me-1 ${inputPosition === 'bottom' ? 'mb-2' : 'mt-2'}`,
              remove: 'btn btn-close btn-close-white hide-text',
            }}
            tags={tags}
            placeholder={placeholder}
            delimiters={delimiters}
            handleDelete={(i) => {
              const tagsFiltered = tags.filter((tag, index) => index !== i);
              const mappedTags = tagsFiltered.map((tag) => tag.text);
              setTags(tagsFiltered);
              onChange(mappedTags);
              onChangeCallback(mappedTags);
            }}
            handleAddition={(tag) => {
              const updatedTags = tags ? [...tags, tag] : [tag];
              const mappedTags = updatedTags.map((tag) => tag.text);
              setTags(updatedTags);
              onChange(mappedTags);
              onChangeCallback(mappedTags);
            }}
            inputFieldPosition={inputPosition}
          />
        )}
      />
    </Form.Group>
  );
};
