export const ProcessTypes = [
  {
    name: 'Manualnie',
    value: 'MANUAL',
  },
  {
    name: 'Automatycznie',
    value: 'AI',
  },
  {
    name: 'Odłóz na później',
    value: 'POSTPONE',
  },
];
