import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ENV } from '#const';
import { Router } from './Router';

import './scss/volt.scss';
import './index.css';

const env = process.env.REACT_APP_ENV;

// if (env === ENV.PROD) {
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);
// }

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return <RouterProvider router={Router} />;
}

export default App;
