import React, { useState } from 'react';
import { Button, Row, Col, Badge, Modal } from 'react-bootstrap';
import { MedicalDocument } from '#components';
import { Document } from '#atoms';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const MedicalDocumentsGallery = ({ data = [], onRemove = () => {}, hideAI = false }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => setShowModal(false);
  const openModal = (document) => setShowModal(document);

  return (
    <>
      <Row>
        {data?.map?.((document) => (
          <Col xs={12} lg={6} xl={4} key={document.id} className='mb-3'>
            <Document isMiniature>

              <Document.Title>
                {t(`title.${document.document_type.toLowerCase()}`)}
                <div className='h6'>
                  <span>z dnia {moment(document.date).format('y-MM-DD')}</span>
                </div>
              </Document.Title>

              <Document.Subtitle>
                {document.tags?.map((tag) => (
                  <Badge key={tag} bg='primary' className='m-1'>
                    {tag}
                  </Badge>
                ))}
              </Document.Subtitle>

              {document.text_content ? (
                <Document.Truncated>{document.text_content}</Document.Truncated>
              ) : (
                <Document.BgImage url={`url(${document.images?.[0]?.signed_url})`} />
              )}

              <Document.Actions>
                <Button
                  variant='success'
                  size='sm'
                  className='mx-2'
                  onClick={() => {
                    openModal(document);
                  }}
                >
                  Wyświetl dokument
                </Button>
                <Button
                  variant='danger'
                  size='sm'
                  className='mx-2'
                  onClick={() => {
                    onRemove(document);
                  }}
                >
                  Odznacz dokument
                </Button>
              </Document.Actions>
            </Document>
          </Col>
        ))}
      </Row>
      <Modal size={'xl'} show={showModal} onHide={closeModal}>
        <Modal.Body>
          <MedicalDocument data={showModal} hideAI={hideAI} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
