import React, { useCallback } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { useCreateHealthDataMutation } from '#store';
import '#atoms/survey/timeout';
// TODO: dodawać do historii przeglądarki zeby mozna bylo cofac
import surveyJson from './survey.json';
import './survey.css';

export const HealthDataSurvey = ({ onComplete }) => {
  const survey = new Model(surveyJson);

  const [createHealthDataMutation] = useCreateHealthDataMutation();

  survey.showPageNumbers = false;
  survey.showQuestionNumbers = false;

  survey.onComplete.add(async ({ data }) => {
    const { data: createdDocument } = await createHealthDataMutation(data);

    console.log('createdDocument: ', createdDocument);

    onComplete(createdDocument);
  });

  return (
    <>
      <Survey model={survey} />
    </>
  );
};
