export const DocumentTypes = [
  {
    name: 'Diagnoza Lekarska',
    value: 'DIAG',
  },
  {
    name: 'Badanie Laboratoryjne',
    value: 'LAB',
  },
];
