import React, { useCallback } from 'react';
import moment from 'moment';
import { Col, Row, Card, Image, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationBar, ProductsPricing } from '#components';
import { Routes } from '#Routes';
import { Link } from 'react-router-dom';

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = moment().get('year');

  const selectProduct = useCallback(() => {
    navigate(Routes.AccountSubscribe.path);
  }, [navigate]);

  return (
    <>
      <NavigationBar />

      <section
        className='section-header pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white overflow-hidden'
        id='home'
      >
        <Container>
          <Row>
            <Col xs={12} className='text-center'>
              <h1 className='fw-bolder text-secondary mb-5 mt-5'>{t('home.content.title1')}</h1>
              <p className='fw-light mb-5 h3'>{t('home.content.subtitle1')} </p>
              <p className='fw-light mb-5 h3'>{t('home.content.subtitle2')} </p>
              {/* <p className='fw-light mb-5 h3'>{t('home.content.subtitle3')} </p> */}
            </Col>
          </Row>
          <div className='react-homepage-icon d-none d-lg-block'>
            <Image
              src='medical_document2.png'
              width='100%'
              style={{ filter: 'brightness(0.4) blur(1px)', maxWidth: '100%', height: 'auto' }}
              alt={t('image.altText')}
            />
          </div>
        </Container>
      </section>

      <section className='section section-md bg-soft pt-lg-3' id='features'>
        <Container>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5} className='order-lg-2 mb-5 mb-lg-0'>
              <h2 className='mt-3'>{t('home.row1.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row1.subtitle')}</p>
              <p className='mb-3'>{t('home.row1.text1')}</p>
              <p className='mb-3'>{t('home.row1.text2')}</p>
              <p className='mb-3'>{t('home.row1.text3')}</p>
              <p className='mb-3'>{t('home.row1.text4')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <Image
                src='data_safety_2_1.jpg'
                alt={t('image.altText')}
                style={{ height: 'auto', maxHeight: '400px' }}
                className='img-fluid'
              />
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5}>
              <h2 className='mb-3'>{t('home.row2.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row2.subtitle')}</p>
              <p className='mb-3'>{t('home.row2.text1')}</p>
              <p className='mb-3'>{t('home.row2.text2')}</p>
              <p className='mb-3'>{t('home.row2.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <video width='100%' controls>
                <source src='/lab_test_720_4x.mp4' type='video/mp4' />
                {t('video.notSupported')}
              </video>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5} className='order-lg-2 mb-5 mb-lg-0'>
              <h2>{t('home.row3.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row3.subtitle')}</p>
              <p className='mb-3'>{t('home.row3.text1')}</p>
              <p className='mb-3'>{t('home.row3.text2')}</p>
              <p className='mb-3'>{t('home.row3.text3')}</p>
            </Col>
            <Col lg={6}>
              <video width='100%' controls>
                <source src='/create_doc_report_720_4x.mp4' type='video/mp4' />
                {t('video.notSupported')}
              </video>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5}>
              <h2>{t('home.row3_1.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row3_1.subtitle')}</p>
              <p className='mb-3'>{t('home.row3_1.text1')}</p>
              <p className='mb-3'>{t('home.row3_1.text2')}</p>
              <p className='mb-3'>{t('home.row3_1.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <video width='100%' controls>
                <source src='/create_health_report_720_4x.mp4' type='video/mp4' />
                {t('video.notSupported')}
              </video>
            </Col>
          </Row>
          {/* <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5} className='order-lg-2 mb-5 mb-lg-0'>
              <h2>{t('home.row3_2.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row3_2.subtitle')}</p>
              <p className='mb-3'>{t('home.row3_2.text1')}</p>
              <p className='mb-3'>{t('home.row3_2.text2')}</p>
              <p className='mb-3'>{t('home.row3_2.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <p>image placeholder</p>
            </Col>
          </Row> */}
          {/* <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5}>
              <h2>{t('home.row4.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row4.subtitle')}</p>
              <p className='mb-3'>{t('home.row4.text1')}</p>
              <p className='mb-3'>{t('home.row4.text2')}</p>
              <p className='mb-3'>{t('home.row4.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <p>image placeholder</p>
            </Col>
          </Row> */}
          {/* <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5} className='order-lg-2 mb-5 mb-lg-0'>
              <h2>{t('home.row5.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row5.subtitle')}</p>
              <p className='mb-3'>{t('home.row5.text1')}</p>
              <p className='mb-3'>{t('home.row5.text2')}</p>
              <p className='mb-3'>{t('home.row5.text3')}</p>
            </Col>
            <Col lg={6} className='order-lg-1'>
              <p>image placeholder</p>
            </Col>
          </Row> */}
          {/* <Row className='justify-content-between align-items-center mb-5 mb-lg-7'>
            <Col lg={5}>
              <h2>{t('home.row6.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row6.subtitle')}</p>
              <p className='mb-4'>{t('home.row6.text1')}</p>
              <p className='mb-4'>{t('home.row6.text2')}</p>
            </Col>
            <Col lg={6}>
              <p>image placeholder</p>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center'>
            <Col lg={5} className='order-lg-2 mb-5 mb-lg-0'>
              <h2>{t('home.row7.title')}</h2>
              <p className='mb-3 lead fw-bold'>{t('home.row7.subtitle')}</p>
              <p className='mb-4'>{t('home.row7.text1')}</p>
              <p className='mb-4'>{t('home.row7.text2')}</p>
              <p className='mb-4'>{t('home.row7.text3')}</p>
            </Col>
            <Col lg={6} className='col-lg-6 order-lg-1'>
              <p>image placeholder</p>
            </Col>
          </Row> */}
        </Container>
      </section>

      <section id='pricing'>
        <ProductsPricing onSelectProduct={selectProduct} />
      </section>

      {/* <section id='contact'>
            <ContactForm />
          </section> */}

      <section id='contact'>
        <footer className='footer py-6 bg-primary text-white'>
          <Container>
            <Row>
              <Col md={4}>
                {/* <Navbar.Brand as='#' to='#root' className='me-lg-3 mb-3 d-flex align-items-center'>
                    <Image src='' />
                    <span className='ms-2 brand-text'>Volt React Pro</span>
                  </Navbar.Brand> */}
                <h5>{t('home.footer.title')}</h5>
              </Col>
              <Col xs={6} md={2} className='mb-5 mb-lg-0'>
                {/* <span className='h5'>Medify.me</span> */}
                <Image src='medifyme-no-icon.png' alt='Logo' />
                <ul className='links-vertical mt-2'>
                  <li>
                    <Card.Link href='#'>{t('home.footer.link1')}</Card.Link>
                  </li>
                  <li>
                    <Card.Link href='#pricing'>{t('home.footer.link3')}</Card.Link>
                  </li>
                </ul>
              </Col>
              <Col xs={6} md={2} className='mb-5 mb-lg-0'></Col>
              <Col xs={12} md={4} className='mb-5 mb-lg-0'>
                <form action='#'>
                  <div className='form-row mb-2'>
                    <div className='col-12'>
                      {/* <input type='email' className='form-control mb-2' placeholder='example@company.com' name='email' aria-label='Subscribe form' required /> */}
                    </div>
                    <div className='col-12 d-grid gap-2'>
                      {/* <button type='submit' className='btn btn-secondary text-dark shadow-soft' data-loading-text='Sending'> */}
                      <a
                        href='mailto:info@medify.me'
                        target='_blank'
                        className='btn btn-secondary'
                        rel='noreferrer'
                      >
                        {t('home.footer.email')}
                      </a>
                      {/* <span>Send Email</span> */}
                      {/* </button> */}
                    </div>
                  </div>
                </form>
                <p className='text-muted font-small m-0'>
                  {t('home.footer.privacy_text')}{' '}
                  <Card.Link as={Link} to={Routes.Privacy.path} className='text-white' href='#'>
                    {t('home.footer.privacy')}
                  </Card.Link>
                </p>
                <p className='text-muted font-small m-0'>
                  {t('home.footer.terms_text')}{' '}
                  <Card.Link as={Link} to={Routes.Terms.path} className='text-white' href='#'>
                    {t('home.footer.terms')}
                  </Card.Link>
                </p>
                <p className='text-muted font-small m-0'>
                  <Card.Link as={Link} to={Routes.Disclaimers.path} className='text-white' href='#'>
                    {t('home.footer.disclaimers')}
                  </Card.Link>
                </p>
              </Col>
            </Row>
            <hr className='bg-gray-600 my-5' />
            <Row>
              <Col className='mb-md-2'>
                <Card.Link href='#home' className='d-flex justify-content-center'>
                  <Image
                    src='logo-no-background.png'
                    height={35}
                    className='d-block mx-auto mb-3'
                    alt='Medify.me Logo'
                  />
                </Card.Link>
                <div
                  className='d-flex text-center justify-content-center align-items-center'
                  role='contentinfo'
                >
                  <p className='font-weight-normal font-small mb-0'>
                    {t('home.footer.copyright')} © Medify.me 2023-
                    <span className='current-year'>{currentYear}</span>. {t('home.footer.rights')}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </section>
    </>
  );
}
