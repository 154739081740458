import React, { useState } from 'react';
import { Image, Container, Navbar, Nav, Button } from 'react-bootstrap';
import { LogoutIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import TranslationItem from '#components/TranslationItem';
import { Routes } from '#Routes';
import { useSelector } from 'react-redux';
import { selectIsLogged } from '#store';
import { useAuth } from '#hooks';

const Navigationbar = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLogged);
  const { logout } = useAuth();
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  return (
    <Navbar
      variant='dark'
      expand='lg'
      bg='dark'
      className='navbar-transparent navbar-theme-primary sticky-top'
      expanded={expanded}
    >
      <Container className='position-relative justify-content-between px-3'>
        <Navbar.Toggle aria-controls='navbarScroll' onClick={handleToggle} />
        <Navbar.Collapse id='navbarScroll' className='navbar-theme-primary px-4 d-md-none'>
          <Navbar.Brand as={HashLink} to='#home' className='me-lg-3 d-flex align-items-center'>
            <Image src='medifyme-align-left.png' alt='Logo' className='d-none d-lg-block' />
            <Image
              src='medifyme-icon-only.png'
              alt='Logo'
              className='d-lg-none mx-auto d-flex justify-content-center align-items-center mb-2'
              style={{ width: '30px', height: '30px' }}
              onClick={handleClose}
            />
          </Navbar.Brand>
          <Nav className='me-auto'>
            <Button
              variant='outline-light'
              as={Link}
              to={Routes.Dashboard.path}
              className='me-2 text-secondary'
            >
              {t('home.items.dashboard')}
            </Button>
            <Button
              variant='outline-light'
              eventKey='2'
              as={HashLink}
              to='#pricing'
              className='me-2 text-secondary'
              onClick={handleClose}
            >
              {t('home.items.pricing')}
            </Button>
            <Button
              variant='outline-light'
              eventKey='3'
              as={HashLink}
              to='#contact'
              className='me-2 text-secondary'
              onClick={handleClose}
            >
              {t('home.items.contact')}
            </Button>
          </Nav>
          <Nav>
            {isLoggedIn ? (
              <Button
                variant='outline-light'
                onClick={() => {
                  logout();
                  handleClose();
                }}
                className='text-secondary'
              >
                <LogoutIcon className='icon icon-xs me-2' />
                {t('sidebar.items.logout')}
              </Button>
            ) : (
              <>
                <Button
                  variant='outline-light'
                  as={Link}
                  to={Routes.Login.path}
                  className='me-2 text-secondary'
                >
                  {t('auth.login')}
                </Button>
                <Button
                  variant='outline-light'
                  as={Link}
                  to={Routes.Register.path}
                  className='text-secondary'
                >
                  {t('auth.register')}
                </Button>
              </>
            )}
            <TranslationItem />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
