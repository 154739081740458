import { baseApi } from './baseApi';

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    confirmEmail: builder.mutation({
      query: (key) => ({
        url: `/accounts/registration/account-confirm-email/`,
        method: 'POST',
        body: { key },
      }),
    }),
    resendConfirmationEmail: builder.mutation({
      query: ({ email }) => ({
        url: `/accounts/registration/account-resend-confirmation-mail/`,
        method: 'POST',
        body: { email },
      }),
    }),
    changePassword: builder.mutation({
      query: ({ oldPassword, newPassword1, newPassword2 }) => ({
        url: `/accounts/password/change/`,
        method: 'POST',
        body: {
          old_password: oldPassword,
          new_password1: newPassword1,
          new_password2: newPassword2,
        },
      }),
    }),
  }),
});

export const {
  useConfirmEmailMutation,
  useResendConfirmationEmailMutation,
  useChangePasswordMutation,
} = authApi;
