import React, { useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

// import i18n from '../i18n/config';
import { useTranslation } from 'react-i18next';

import UnitedKingdomFlag from '#assets/img/flags/gb.svg';
import PlFlag from '#assets/img/flags/pl.svg';

const TranslationItem = () => {
  const { t, i18n } = useTranslation();

  const options = [
    {
      value: 'en',
      label: (
        <span>
          <img src={UnitedKingdomFlag} width='20' alt='' />
        </span>
      ),
    }, //en
    {
      value: 'pl',
      label: (
        <span>
          <img src={PlFlag} width='20' alt='' />
        </span>
      ),
    }, //pl
  ];

  const [lang, setLang] = useState(options[1].value);
  const [langLabel, setLangLabel] = useState(options[1].label);

  useEffect(() => {
    setLangLabel(options.find((option) => option.value === lang).label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    const savedLang = localStorage.getItem('lang');
    if (savedLang && options.some((option) => option.value === savedLang)) {
      setLang(savedLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (n) => {
    const selectedLang = options[n].value;
    i18n.changeLanguage(selectedLang);
    setLang(selectedLang);
    localStorage.setItem('lang', selectedLang);
  };

  return (
    <Dropdown as={ButtonGroup} className=''>
      <Dropdown.Toggle className='text-secondary bg-transparent' id='lng-dropdown'>
        {langLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item key={option.value} onClick={() => changeLanguage(index)}>
            {option.label}
            <span className='ms-2 fw-bold'>{t(`languages.${option.value}`)}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TranslationItem;
