import React from 'react';
import { Button, ButtonGroup, Card, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import {
  BadgeCheckIcon,
  CloudIcon,
  EyeIcon,
  FolderDownloadIcon,
  PencilAltIcon,
  TrashIcon,
  AcademicCapIcon,
  CollectionIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';
import { Routes } from '#Routes';
import { alert } from '#utils';
import {
  useConfirmMedicalDocumentMutation,
  useProcessMedicalDocumentMutation,
  useReportMedicalDocumentMutation,
  useDeleteMedicalDocumentMutation,
  useFetchSubscriptionStatusQuery,
} from '#store';
import { MEDICAL_DOCUMENT_ACTION_TYPES } from '#const';

export const MedicalDocumentActions = ({
  actions,
  displayButtons = false,
  MedicalDocument: data,
  callbacks,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [confirmMedicalDocumentMutation] = useConfirmMedicalDocumentMutation();
  const [processMedicalDocumentMutation] = useProcessMedicalDocumentMutation();
  const [reportMedicalDocumentMutation] = useReportMedicalDocumentMutation();
  const [deleteMedicalDocumentMutation] = useDeleteMedicalDocumentMutation();

  const { data: subscriptionStatus } = useFetchSubscriptionStatusQuery();

  const noCreditsAlert = () => {
    const title =
      subscriptionStatus?.currentSubs?.length > 0 ? 'Not enough credits' : 'No active subscription';
    const text =
      subscriptionStatus?.currentSubs?.length > 0
        ? 'You have used all of your credits. In order to proceed please upgrade your subscription plan.'
        : 'You do not have active subscription. In order to proceed please purchase a subscription plan.';

    alert
      .fire({
        icon: 'warning',
        title,
        text,
        showCancelButton: true,
        confirmButtonText: 'Go to Subscription page',
        cancelButtonText: 'Not now',
      })
      .then((result) => {
        if (result.isConfirmed) {
          navigate(Routes.AccountSubscribe.path);
        }
      });
  };

  const processingInProgressAlert = () => {
    alert.fire({
      icon: 'warning',
      title: 'Document processing in progress',
      text: 'Your document is being processed. Please wait until processing is finished.',
      confirmButtonText: 'Ok',
    });
  };

  const confirmDocument = async () => {
    if (data.confirmed) return;

    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        icon: 'question',
        title: 'Confirm approving',
        text: 'Are you sure do you want to confirm this document?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          alert.fire({
            title: 'Confirming Document...',
            didOpen: () => alert.showLoading(),
          });

          const { error } = await confirmMedicalDocumentMutation({ uuid: data.uuid });

          if (error) {
            return alert.fire({
              icon: 'error',
              title: 'Error!',
              text:
                error.data?.message ||
                'There was an error confirming your medical document. Please try again.',
            });
          }

          return alert.fire({
            icon: 'success',
            title: 'Document confirmed!',
            text: 'Your Medical Document has been confirmed',
          });
        }
      });
  };

  const deleteDocument = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        icon: 'warning',
        title: 'Confirm deleting',
        text: 'Are you sure do you want to delete this document?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          alert.fire({
            title: 'Deleting Document...',
            didOpen: () => alert.showLoading(),
          });

          const { error } = await deleteMedicalDocumentMutation({ id: data.id });

          if (error) {
            return alert.fire({
              icon: 'error',
              title: 'Error!',
              text:
                error.data?.message || // e.g. "Cannot delete this document, it has been used in health report."
                'There was an error when deleting Medical Document. Please try again.',
            });
          }

          return alert
            .fire({
              icon: 'success',
              title: 'Document deleted!',
              text: 'Your Medical Document has been deleted.',
            })
            .then(callbacks && callbacks[MEDICAL_DOCUMENT_ACTION_TYPES.DELETE]?.());
        }
      });
  };

  const downloadDocument = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    return alert
      .fire({
        title: 'Generating Document...',
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        didOpen: async () => {
          alert.showLoading();
          await callbacks[MEDICAL_DOCUMENT_ACTION_TYPES.DOWNLOAD]?.();
          alert.close();
        },
      })
      .then(() => {
        return alert.fire({
          icon: 'success',
          title: 'PDF generated!',
          text: 'Your Medical Document PDF has been generated. Check your downloads folder.',
        });
      });
  };

  const processDocument = async () => {
    const remainingCredits =
      subscriptionStatus.creds + subscriptionStatus.extra_credits - subscriptionStatus.counts;

    if (remainingCredits <= 0) {
      return noCreditsAlert();
    }

    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    if (data.process_status === 'FINISHED') {
      return alert.fire({
        icon: 'warning',
        title: 'Document already processed.',
        text: 'Your Medical Document has been processed already. You can not process document twice.',
      });
    }

    return alert
      .fire({
        icon: 'info',
        title: 'Confirm processing',
        text: `Are you sure do you want to process this document? You will use 1 of your ${remainingCredits} remaining credits.`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const { error } = await processMedicalDocumentMutation({ uuid: data.uuid });

          if (error) {
            if (error.status === 402) return noCreditsAlert();

            return alert.fire({
              icon: 'error',
              title: 'Error!',
              text:
                error.data?.message ||
                'There was an error when processing Medical Document. Please try again.',
            });
          }

          return alert.fire({
            icon: 'info',
            title: 'Started processing document.',
            text: 'Your Medical Document is currently being processed. Please check the list of Medical Documents to see the progress.',
          });
        }
      });
  };

  const generateReport = async () => {
    if (data.process_status === 'IN_PROGRESS') {
      return processingInProgressAlert();
    }

    if (data.report_status === 'IN_PROGRESS') {
      return alert.fire({
        icon: 'warning',
        title: 'AI report generation in progress',
        text: 'AI Report for your document is currently being generated. Please wait until AI Report is finished.',
        confirmButtonText: 'Ok',
      });
    }

    if (subscriptionStatus?.creds + subscriptionStatus?.extra_credits === 0) {
      return noCreditsAlert();
    }

    if (!data.confirmed) {
      return alert.fire({
        icon: 'warning',
        title: 'Document contents not confirmed',
        text: 'Before proceeding to generate report please make sure all data related to your Medical Document is complete and correct. Then please confirm your Medical Document. Afterwards you will be able to generate report.',
        confirmButtonText: 'Back to Document',
      });
    }

    return alert
      .fire({
        icon: 'info',
        title:
          data.report_status === 'FINISHED'
            ? 'AI Report already generated.'
            : 'Confirm report generation',
        text: `${
          data.report_status === 'FINISHED'
            ? 'Your Medical Document has AI Report generated. Do you want to generate report for the same document?'
            : 'Are you sure do you want to generate report for this document?'
        } You will use 1 of your ${subscriptionStatus.creds} remaining credits.`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const { error } = await reportMedicalDocumentMutation({ uuid: data.uuid });

          if (error) {
            if (error.status === 402) return noCreditsAlert();

            return alert.fire({
              icon: 'error',
              title: 'Error!',
              text:
                error.data?.message ||
                'There was an error when generating report for Medical Document. Please try again.',
            });
          }
          return alert.fire({
            icon: 'info',
            title: 'Started generating report.',
            text: 'Your Medical Document is currently being processed and report will be provided soon.',
          });
        }
      });
  };

  const editDocument = async () => {
    navigate(generatePath(Routes.MedicalDocumentEdit.path, { id: data.id }));
  };

  const viewDocument = async () => {
    navigate(generatePath(Routes.MedicalDocument.path, { id: data.id }));
  };

  const viewDocumentsList = () => {
    navigate(Routes.MedicalDocumentsList.path);
  };

  const CTA = displayButtons ? Button : Card.Link;

  const actionsMarkup = {
    [MEDICAL_DOCUMENT_ACTION_TYPES.CONFIRM]: (
      <OverlayTrigger
        overlay={
          <Tooltip className='m-0'>
            {data.confirmed
              ? t('medical-document-actions.document-confirmed')
              : t('medical-document-actions.document-confirm')}
          </Tooltip>
        }
      >
        <CTA
          variant={data.confirmed ? 'success' : 'gray-800'}
          className='ms-2'
          onClick={() => confirmDocument()}
        >
          <BadgeCheckIcon
            className={
              !displayButtons && data.confirmed
                ? 'icon icon-xs text-success'
                : 'icon icon-xs text-white'
            }
          />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.DELETE]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.delete')}</Tooltip>}
      >
        <CTA variant='danger' className='ms-2' onClick={() => deleteDocument()}>
          <TrashIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'danger'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.DOWNLOAD]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.download')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => downloadDocument()}>
          <FolderDownloadIcon
            className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`}
          />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.EDIT]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.edit')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => editDocument()}>
          <PencilAltIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.LIST]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.documents-list')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => viewDocumentsList()}>
          <CollectionIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.PROCESS]: (
      <OverlayTrigger
        overlay={
          <Tooltip className='m-0'>
            {data.process_status === 'IN_PROGRESS' ? (
              <>
                <Spinner className='me-2' style={{ width: '16px', height: '16px' }} />
                <span>Process Status</span>
              </>
            ) : (
              t('medical-document-actions.process')
            )}
          </Tooltip>
        }
      >
        <CTA variant='info' className='ms-2' onClick={() => processDocument()}>
          <CloudIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'info'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.REPORT]: (
      <OverlayTrigger
        overlay={
          <Tooltip className='m-0'>
            {data.report_status === 'IN_PROGRESS' ? (
              <>
                <Spinner className='me-2' style={{ width: '16px', height: '16px' }} />
                <span>Preparing AI report</span>
              </>
            ) : (
              t('medical-document-actions.create-report')
            )}
          </Tooltip>
        }
      >
        <CTA variant='info' className='ms-2' onClick={() => generateReport()}>
          <AcademicCapIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'info'}`} />
        </CTA>
      </OverlayTrigger>
    ),
    [MEDICAL_DOCUMENT_ACTION_TYPES.VIEW]: (
      <OverlayTrigger
        overlay={<Tooltip className='m-0'>{t('medical-document-actions.view')}</Tooltip>}
      >
        <CTA variant='gray-800' className='ms-2' onClick={() => viewDocument()}>
          <EyeIcon className={`icon icon-xs text-${displayButtons ? 'white' : 'grey'}`} />
        </CTA>
      </OverlayTrigger>
    ),
  };

  return (
    data && (
      <ButtonGroup>
        {actions.map((action) => (
          <div key={action}>{actionsMarkup[action]}</div>
        ))}
      </ButtonGroup>
    )
  );
};
