import React from 'react';
import Markdown from 'react-markdown';
import { Placeholder, Badge, Card, Image, Spinner, ListGroup, Row, Col } from 'react-bootstrap';
import ThemesbergLogo from '#assets/img/themesberg.svg';
import LogoIcon from '#assets/img/medifyme-icon-only.png';
import LogoNoIcon from '#assets/img/medifyme-no-icon.png';
import cx from 'classnames';
import './styles.scss';

const Header = () => (
  <Card.Header>
    <div className='d-flex justify-content-between mb-3'>
      <Image src={LogoIcon} className='image-md' />
      <div>
        {/* <h4>Medify.me</h4> */}
        <Image src={LogoNoIcon} className='image-sm' />
        <ListGroup className='simple-list'>
          {/* <ListGroup.Item className='fw-normal'>112 Washington Square</ListGroup.Item>
          <ListGroup.Item className='fw-normal'>New York, USA</ListGroup.Item> */}
          <ListGroup.Item className='fw-normal'>
            <Card.Link className='fw-bold text-primary'>info@medify.me</Card.Link>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  </Card.Header>
);

const HeaderMiniature = () => (
  <Card.Header className='text-center'>
    <div className='d-flex justify-content-between mb-3'>
      <Image src={LogoIcon} className='image-xs' />
      <Image src={LogoNoIcon} className='image-xs' />
    </div>
  </Card.Header>
);

const Footer = () => (
  <small className='d-block mt-4' style={{ fontSize: '0.75rem' }}>
    Analizy sieci neuronowych LLM mają charakter pomocniczy i informacyjny w zakresie prognozowania
    i przewidywania stanu zdrowia i chorób Użytkownika. Wyniki badań laboratoryjnych Użytkownika
    oraz jego diagnozy medyczne są poddane automatycznej ocenie algorytmu sztucznej inteligencji,
    jednak nie mogą zastąpić konsultacji lekarskiej wyników badań oraz być zamiennikiem konsultacji,
    porad medycznych lub diagnozy medycznej - dotyczącej stanu zdrowia Użytkownika, wynikającego ze
    wskazań wiedzy medycznej, nawet jeśli ocena algorytmu sztucznej inteligencji nie zawiera tego
    typu sugestii bądź też brak w tej ocenie stwierdzenia odchyleń w wynikach badań. Analizy
    generowane przez algorytmy sztucznej inteligencji mogą zawierać błędy oraz/lub informacje
    nieprawdziwe więc powinny być w każdym przypadku zweryfikowane przez lekarza.
  </small>
);

// TODO, we could display content placeholder while loading document, but it does not work
// https://react-bootstrap.netlify.app/docs/components/placeholder/#how-it-works
const ContentPlaceholder = () => (
  <div>
    <Placeholder as={Card.Title} animation='glow'>
      <Placeholder xs={6} />
    </Placeholder>
    <Placeholder as={Card.Text} animation='glow'>
      <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
      <Placeholder xs={8} />
    </Placeholder>
  </div>
);

const Document = ({ children, isLoading, documentRef, isMiniature }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && typeof child.type !== 'string') {
      return React.cloneElement(child, { isMiniature });
    }
    return child;
  });

  return (
    <Card
      border='0'
      className={cx('shadow h-100', {
        'p-1 p-lg-2 p-xl-3': isMiniature,
        'p-3 p-lg-4 p-xl-5': !isMiniature,
      })}
      ref={documentRef}
    >
      {isMiniature ? <HeaderMiniature /> : <Header />}
      {isLoading ? (
        <Card.Body className='p-5 d-flex justify-content-center align-items-center'>
          <Spinner size='md' />
          <b className='ms-2'>Loading...</b>
          {/* <ContentPlaceholder /> */}
        </Card.Body>
      ) : (
        <Body isMiniature={isMiniature}>{childrenWithProps}</Body>
      )}
      {!isMiniature && <Footer />}
    </Card>
  );
};

const Body = ({ children, isMiniature }) => (
  <Card.Body
    className={cx('d-flex flex-column', {
      'pt-4 pb-3': isMiniature,
      'pt-5 pb-5': !isMiniature,
    })}
  >
    {children}
  </Card.Body>
);

const Text = ({ title, content }) => {
  if (!content) return;
  return (
    <div className='text-muted pb-4 mb-4 mt-4 border-bottom border-light'>
      <span className='mb-5 h4 d-block'>{title}</span>
      <Markdown className='documentMarkdown'>{content}</Markdown>
    </div>
  );
};

const Truncated = ({ children }) => (
  <div className='text-muted text-truncated mb-4'>
    <Markdown className='documentMarkdown'>{children}</Markdown>
  </div>
);

const Information = ({ children }) => (
  <Row className='justify-content-between mb-4 mb-lg-6'>
    <h5 className='mb-4'>Document Information:</h5>

    <Col className='col-sm'>
      <Row className='text-sm'>{children[0]}</Row>
    </Col>

    <Col md={12} lg={6} xl={5} className='col-sm'>
      <Row className='text-sm-right'>{children[1]}</Row>
    </Col>
  </Row>
);

const Title = ({ children, badge, isMiniature }) => (
  <Card.Title
    className={cx('d-flex align-items-center justify-content-center', {
      'mb-4 h2': isMiniature,
      'mb-6': !isMiniature,
    })}
  >
    <h2 className='mb-0 text-center'>{children}</h2>
    {badge && (
      <Badge bg={badge.bg} className='badge-lg ms-4'>
        {badge.text}
      </Badge>
    )}
  </Card.Title>
);

const Subtitle = ({ children, isMiniature }) => (
  <Card.Subtitle
    className={cx('text-center', {
      'mb-4': isMiniature,
      'mb-6': !isMiniature,
    })}
  >
    <h3>{children}</h3>
  </Card.Subtitle>
);

const Loader = ({ isLoading, text }) => {
  if (!isLoading) return;
  return (
    <div className='p-5 d-flex justify-content-center align-items-center'>
      <Spinner size='md' />
      <b className='ms-2'>{text}</b>
      {/* <ContentPlaceholder /> */}
    </div>
  );
};

const Actions = ({ children, className }) => (
  <div className={cx('text-center mt-5', className)}>{children}</div>
);

const BgImage = ({ url }) => (
  <div style={{ backgroundImage: url }} className='profile-cover rounded-top' />
);

Object.assign(Document, Card, {
  Header,
  Body,
  Footer,
  Title,
  Subtitle,
  Text,
  Truncated,
  Information,
  Loader,
  Actions,
  BgImage,
});

export { Document };
